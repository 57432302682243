var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-data-table',{staticClass:"rounded",attrs:{"headers":_vm.headers,"items":_vm.filteredFarmers,"search":_vm.searchPhrase},on:{"update:search":function($event){_vm.searchPhrase=$event},"click:row":_vm.goToDetails,"pagination":_vm.testPager},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","rounded":"","color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v("Farmers")]),_c('v-spacer'),_c('v-text-field',{attrs:{"placeholder":"Search","prepend-inner-icon":"mdi-magnify","hide-details":"","filled":"","rounded":"","dense":""},model:{value:(_vm.searchPhrase),callback:function ($$v) {_vm.searchPhrase=$$v},expression:"searchPhrase"}}),_c('v-spacer'),_c('v-btn',{staticClass:"primary",attrs:{"text":"","to":{ name: 'add-farmer' },"rounded":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add Farmer ")],1),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.downloadFarmersCSV}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-microsoft-excel")])],1),_c('v-btn',{attrs:{"outlined":!_vm.show_disabled_farmers},on:{"click":_vm.toggleDisabled}},[_c('v-icon',[_vm._v("mdi-eye")]),_vm._v(" Toggle Disabled ")],1)],1)]},proxy:true},{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"72"}},[_c('v-img',{attrs:{"src":item.picture}})],1)]}},{key:"item.farmer_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(item.farmer_id)+" ")])]}},{key:"item.community",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(item.community_id.name)+" ")])]}},{key:"item.level_training",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-chip',{attrs:{"color":"primary"}},_vm._l((item.level_training),function(n){return _c('v-icon',{key:n,attrs:{"color":"white","small":""}},[_vm._v("mdi-star")])}),1)],1)]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("tel:" + (item.phone))}},[_vm._v(_vm._s(item.phone))])]}},{key:"item.age",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getAge(item.date_of_birth)))])]}},{key:"item.farms",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","to":{ name: 'farmer-farms', params: { farmer_id: item._id } },"color":"primary","rounded":"","block":""}},[_vm._v("Farm ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dayjs(item.createdAt).format("YYYY/MM/DD"))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dayjs(item.updatedAt).format("YYYY/MM/DD"))+" ")]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"loading":item.disabling,"color":"error","depressed":"","rounded":"","block":"","outlined":!item.disabled},on:{"click":function($event){$event.stopPropagation();return _vm.disableFarmer(item)}}},[_vm._v(" "+_vm._s(item.disabled ? "Disabled" : "Disable")+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"loading":item.isDeleting,"color":"error","depressed":"","rounded":"","block":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteFarmer(item)}}},[_vm._v(" Delete ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }