<template>
  <v-container>
    <v-card>
      <v-card-title class="primary white--text">
        {{ $route.params.id ? "Edit" : "Adding New" }} Community
      </v-card-title>
      <v-stepper v-model="step">
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card>
              <v-card-text>
                <v-row justify="center">
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="form.name"
                      label="Community Name"
                      :disabled="$route.params.id"
                      rounded
                      filled
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="form.description"
                      label="Description"
                      rounded
                      filled
                    />
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="form.latitude"
                      label="Latitude"
                      type="number"
                      rounded
                      filled
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="form.longitude"
                      label="Longitude"
                      type="number"
                      rounded
                      filled
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="justify-space-between">
                <v-btn @click="step--" depressed rounded x-large>Cancel</v-btn>
                <v-btn
                  @click="saveForm"
                  :loading="loading"
                  rounded
                  x-large
                  color="primary"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
    <v-dialog v-model="map.show">
      <app-map @location="map.callback" @close="closeMap" />
    </v-dialog>
  </v-container>
</template>

<script>
import { Plugins, CameraResultType } from "@capacitor/core";
import { v4 as uuid } from "uuid";
import AppMap from "@/components/MapWithPlaces";

const { Camera } = Plugins;
const MapObj = {
  address: "",
  type: "Point",
  coordinates: [],
};

export default {
  name: "AddFarmer",
  watch: {
    map(map) {
      console.log({ map });
    },
  },
  components: { AppMap },
  data: () => ({
    MapObj,
    step: 1,
    loading: false,
    show: { date_of_birth: false },
    upload_image: {
      ref: uuid(),
      is_uploaded: false,
      is_form_submitted: false,
    },
    options: {
      regions: [],
      employment_status: [
        { value: "artisan", text: "Artisan" },
        { value: "farming", text: "Farming" },
        { value: "others", text: "Others" },
      ],
      educational_level: [
        { value: "primary", text: "Primary" },
        { value: "jhs", text: "JHS" },
        { value: "shs/vocational", text: "SHS/Vocational" },
        { value: "tertiary", text: "Tertiary" },
        { value: "none", text: "None" },
      ],
    },
    form: {},
    map: {
      show: false,
      callback: null,
    },
  }),
  methods: {
    saveForm() {
      this.loading = true;
      (this.$route.params.id
        ? this.$axios.put(`/communities/${this.$route.params.id}`, this.form)
        : this.$axios.post("/communities/", this.form)
      )
        .then((res) => {
          // console.log("saved farmer", res.data);
          if (!this.$route.params.id)
            this.$router.push({
              name: "edit-community",
              params: { id: res.data._id },
            });
        })
        .catch((err) => {
          console.log(err);
          const message = err.response
            ? err.response.data.message
            : err.message;
          this.$root.$emit("error", message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    if (this.$route.params.id) {
      this.loading = true;
      // console.log(`DSFSDFSDFSD: ${this.$route.params.id}`);
      Promise.all([this.$axios.get(`communities/${this.$route.params.id}`)])
        .then((res) => {
          // console.log(res.data)
          this.form = res[0].data[0];
          console.log(JSON.stringify(this.form));
        })
        .finally((_) => {
          this.loading = false;
        });
    }
  },
};
</script>

<style scoped>
</style>
