<template>
  <v-container>
    <v-card>
      <v-data-table class="rounded" :headers="headers" :items="filteredFarmers" :search.sync="searchPhrase"
        @click:row="goToDetails" @pagination="testPager">
        <template v-slot:top>
          <v-toolbar flat rounded color="primary" dark>
            <v-toolbar-title>Farmers</v-toolbar-title>
            <v-spacer />
            <v-text-field v-model="searchPhrase" placeholder="Search" prepend-inner-icon="mdi-magnify" hide-details filled
              rounded dense></v-text-field>
            <v-spacer />
            <v-btn text class="primary" :to="{ name: 'add-farmer' }" rounded>
              <v-icon left>mdi-plus</v-icon>
              Add Farmer
            </v-btn>
            <v-btn icon @click="downloadFarmersCSV">
                <v-icon color="white">mdi-microsoft-excel</v-icon>
            </v-btn>
            <v-btn @click="toggleDisabled" :outlined="!show_disabled_farmers">
              <v-icon>mdi-eye</v-icon> Toggle Disabled
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.avatar="{ item }">
          <v-avatar size="72">
            <v-img :src="item.picture" />
          </v-avatar>
        </template>
        <template v-slot:item.farmer_id="{ item }">
          <v-chip color="primary">
            {{ item.farmer_id }}
          </v-chip>
        </template>
        <template v-slot:item.community="{ item }">
          <v-chip color="primary">
            {{ item.community_id.name }}
          </v-chip>
        </template>
        <template v-slot:item.level_training="{ item }">
          <div>
            <v-chip color="primary">
              <v-icon v-for="n in item.level_training" :key="n" color="white" small>mdi-star</v-icon>
            </v-chip>
          </div>


        </template>
        <template v-slot:item.phone="{ item }">
          <a :href="`tel:${item.phone}`">{{ item.phone }}</a>
        </template>

        <template v-slot:item.age="{ item }">
          <span>{{ getAge(item.date_of_birth) }}</span>
        </template>
        <template v-slot:item.farms="{ item }">
          <v-btn outlined :to="{ name: 'farmer-farms', params: { farmer_id: item._id } }" color="primary" rounded
            block>Farm
          </v-btn>
        </template>
        <template v-slot:item.createdAt="{ item }">
          {{ dayjs(item.createdAt).format("YYYY/MM/DD") }}
        </template>
        <template v-slot:item.updatedAt="{ item }">
          {{ dayjs(item.updatedAt).format("YYYY/MM/DD") }}
        </template>
        <template v-slot:item.details="{ item }">
          <v-btn @click.stop="disableFarmer(item)" :loading="item.disabling" color="error" depressed rounded block
            :outlined="!item.disabled">
            {{ item.disabled ? "Disabled" : "Disable" }}
          </v-btn>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn @click.stop="deleteFarmer(item)" :loading="item.isDeleting" color="error" depressed rounded block>
            Delete
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import config from "@/config";
import firebase from "firebase";
import { downloadxls } from "../../plugins/downloadxlsx";

dayjs.extend(relativeTime);

export default {
  name: "farmers",
  data: () => ({
    searchPhrase: "",
    dayjs,
    disabling_farmer: false,
    show_disabled_farmers: false,
    options: {},
    headers: [
      { align: "center", text: "Profile Img", value: "avatar" },
      { align: "center", text: "ID", value: "farmer_id" },
      { align: "center", text: "Full Name", value: "full_name" },
      { align: "center", text: "Age", value: "age" },
      { align: "center", text: "Gender", value: "gender" },
      { align: "center", text: "Farmer Community", value: "community" },
      { align: "center", text: "Buying Company", value: "buying_company" },
      { align: "center", text: "Momo Number", value: "momo" },
      { align: "center", text: "Ghana Card ID", value: "ghana_card" },
      { align: "center", text: "Farms", value: "farms" },
      { align: "center", text: "Training Level", value: "level_training" },
      { align: "center", text: "Details", value: "details" },
      { align: "center", text: "Actions", value: "actions" },
      { align: "center", text: "Date Created", value: "createdAt" },
      { align: "center", text: "Last Updated ", value: "updatedAt" },
    ],
    config,
    token: null,
    farmers: [],
    downloading: false,
  }),
  computed: {

    filteredFarmers() {
      return (
        (this.show_disabled_farmers ? this.farmers : this.farmers) || []
      ).filter((farmer) => !farmer.disabled);
    },
  },
  methods: {
    deleteFarmer(farmer) {
      // this.$swal.fire("Farmer Deleted", "", "success");

      // console.log(farmer, this.$route.params);
      this.$swal({
        icon: "error",
        title: `Are you sure you want to delete farmer ${farmer.farmer_id}?`,
        confirmButtonColor: "#d33",
        showCancelButton: true,
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.isConfirmed) {
          farmer.isDeleting = true;
          this.$axios
            .delete(`farmer/${farmer._id}`)
            .then(() => { })
            .finally(() => {
              farmer.isDeleting = false;
              this.farmers.splice(this.farmers.indexOf(farmer), 1);
              this.snackbar = true;
              this.$swal.fire("Farmer Deleted", "", "success");
            });
        }
      });
      console.log(this.farmer);
    },
    toggleDisabled() {
      this.show_disabled_farmers = !this.show_disabled_farmers
    },

    getAge(date) {
      return dayjs(date).fromNow(true);
    },

    goToDetails(farmer, options) {
      this.$router.push({ name: "farmer", params: { farmer_id: farmer._id } });
    },
    async disableFarmer(item) {
      item.disabling = !item.disabling;
      this.$axios.put(`/farmer/${item._id}`, { disabled: !item.disabled });
      item.disabled = !item.disabled;
      item.disabling = !item.disabling;
    },
    downloadFarmersCSV() {
      this.exportFarmers = this.farmers.map((farmer) => ({
        ...farmer,
        region: farmer.region?.region,
        household_status: farmer.household_status?.replace("Household Status", ""),
        community: farmer.community_id?.name,
        }));
      downloadxls(this.exportFarmers, "farmers")
      
    },
  },
  async mounted() {
    const { token, claims } = await firebase
      .auth()
      .currentUser.getIdTokenResult(true);
    this.token = token;
    this.claims = claims;

    this.$axios
      .get("/farmer")
      .then((res) => {
        this.farmers = (res.data || []).map((farmer) => ({
          ...farmer,
          disabling: false,
        }));
      })
      .catch((err) => {
        console.error(err);
      });
  },
};

</script>
